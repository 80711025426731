<!--
 * @Descripttion: 特别明细
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-05-29 10:04:00
-->
<template>
  <div class="bg vh">
    <van-nav-bar title="鸣谢" left-arrow @click-left="$router.push('/mine')"  />
    <p class="bb">欢迎青岛实讯科技有限公司入驻专家记~</p>
    <div class="t">
      <h3>本平台建设及运行，<span class="gg">特别感谢</span>以下机构和人员！</h3>
      <p class="gg" @click="$router.push('/org')">查看入驻名单 ></p>
      <p class="cc first-c"></p>
      <p class="cc second-c"></p>
      <p class="cc third-c"></p>
      <p class="cc fourth-c"></p>
    </div>

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      class="pad34"
    >
      <ul>
        <li
          class="org"
          v-for="(item,index) in list"
          :key="index"
        >
          <img :src="logo(item.logo)">
          <div>
            <h3>{{ item.name }} <label v-for="(label,index) in item.labels">{{ label }}</label><i class="icon-go"></i></h3>
            <p><i class="icon-org"></i>{{ item.orgName }}</p>
          </div>
        </li>
      </ul>
    </van-list>
  </div>
</template>

<script>
import { List } from 'vant'
export default {
  name: 'Thanks',
  data(){
    return {
      loading: false,
      finished: false,
      list: [],
      params: {
        pageNo: 1,
        pageSize: 10,
        enableStatus: '0'
      },
      testData : [],
    }
  },
  components: {
    [List.name]:List
  },

  computed:{
    logo(){
      return logo => logo? logo : '/static/img/icon/logo.png'
    }
  },

  methods: {
    onLoad(){
      this.$ajax({
        url: '/gateway/ed/thanks/tbEdThanks/list',
        params: this.params,
        header: { "X-Access-Token": this.$ls.get('token') }
      }).then( res => {
        if(res.data.success){
          this.loading = false
          this.list = this.list.concat(res.data.result.records)
          console.log(this.list);
          if(this.list.length >= res.data.result.total){
            this.finished = true
            return
          }
          this.params.pageNo++
        }
      }).catch( err => console.log(err))
    },

    /**
     * @Description: 跳转详情页
     * @Author: Niklaus
     * @Date: 2021-05-29 14:58:36
     */
    goInfo(item){
      this.$router.push({
        name: 'orgInfo',
        params : {
          org: item
        }
      })
    }
  }
}
</script>

<style scoped>
  .gg {color: #FFE080;}
  .bb {background-color: #FF7684; color:white; padding: .21rem .4rem; font-size: .27rem; line-height: .37rem;}
  .t{background-color: #F63A66; color: white; position: relative; padding: .51rem .48rem; overflow: hidden;}
  .t > h3, .t >.gg {position: relative; z-index: 1;}
  .t> h3{font-size: .43rem;}
  .t> h3 > span {font-size: .48rem;}
  .t> .gg {margin-top: .32rem; font-size: .32rem; line-height: .45rem;}
  .cc {
    position: absolute;
    border-radius: 50%;
    background:linear-gradient( #ffb506 0%,#fd8a14 40% ,#fc761b 60% ,#fe5136 100% );
  }
  .first-c {height: .6rem; width: .6rem; top: 0.21rem; left: .21rem;}
  .second-c {height: .9rem; width: .9rem; top: .8rem; left: 25%;}
  .third-c {height: .9rem; width: .9rem; bottom: -10%; left: 53%;}
  .fourth-c {height: 1.5rem; width: 1.5rem; right: -0.4rem; top: .5rem;}
  .pad34 {padding: .32rem .43rem;}
  .org {
    position: relative;
    padding: .32rem .21rem;
    border-radius: 0.2rem;
    color: #1e1e1e;
    display: flex;
    background-color: #fff;
  }
  .org:not(:first-child){margin-top: .32rem;}
  .org img {width: 1.17rem; height: 1.17rem; object-fit: cover;}
  .org >div {padding-left: .21rem;}
  .org h3 {font-size: .43rem; line-height: .59rem; display: flex; align-items: center; }
  .org h3 >label {display:inline-block;margin-left:.32rem; padding: 0 .21rem; color: #00CCB2; font-size: .27rem; line-height: .48rem; background-color: #E1FFFB;}
  .org p {font-size: .35rem; line-height: .48rem; margin-top: .11rem; display: flex; align-items: center;}
  .org p >i {width: .43rem; height: .43rem;margin-right: .11rem;}
  .icon-go {width: .43rem; height: .43rem; position: absolute; right: .21rem; }
</style>